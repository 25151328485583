


















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import algoliasearch from "algoliasearch";
import Common from "../../common/Common";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Paginate from "vuejs-paginate";
import removeMd from "remove-markdown";
require("moment-duration-format");

@Component({
  components: {
    ClipLoader,
    Paginate
  }
})
export default class ClassList extends Vue {
  LOAD_COUNT = 10;
  rMd = removeMd;
  isLoading = true;
  viewDetailSearchTab = false;
  category = "";
  categories: any[] = [];
  viewUserName = "";
  searchUid = Common.getUserId();
  searchCategory = "";
  searchSort = "date";
  searchValue = "";
  searchReward = "";
  searchRate = "";
  questions: {
    id: string;
    title: string;
    detail: string;
    tags: string[];
    date: string;
    userName: string;
  }[] = [];
  alreadyReadResult: any = "";
  endDataCheck = false;
  pages = 1;
  page = 1;

  clickCallback(pageNum: number) {
    this.isLoading = true;
    this.page = pageNum;
    this.$router.replace({
      name: "cslist",
      query: {
        page: String(pageNum),
        text: this.searchValue,
        category: this.$route.query.category,
        uid: this.searchUid
      }
    });
    this.search();
  }

  @Watch("$route")
  changeRouter() {
    if (this.category) {
      if (!this.$route.query.category) {
        if (this.category == "すべてのカテゴリー") {
          return;
        }
      } else {
        let category = this.categories.find((category: any) => {
          return category.value == this.$route.query.category;
        }).text;

        if (category == this.category) {
          return;
        }
      }
    }
    this.searchValue = "";
    this.searchSort = "date";
    this.searchReward = "";
    this.init();
  }

  mounted() {
    this.init();
  }

  init() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    } else {
      sessionStorage.removeItem("cslist");
    }

    if (this.$route.query.text) {
      this.searchValue = <string>this.$route.query.text;
    }

    let session: any = sessionStorage.getItem("cslist");
    if (session) {
      session = JSON.parse(session);
      this.searchSort = session.searchSort
        ? session.searchSort
        : this.searchSort;
      this.searchReward = session.searchReward
        ? session.searchReward
        : this.searchReward;
      this.endDataCheck =
        session.endDataCheck == true ? session.endDataCheck : this.endDataCheck;
      if (session.searchReward || session.endDataCheck) {
        this.viewDetailSearchTab = true;
      }
    }

    if (this.$route.query.uid) {
      this.searchUid = <string>this.$route.query.uid;
    }

    Common.getCategory().then((categories: any) => {
      this.categories = categories;
      if (this.$route.query.category) {
        this.category = categories.find((category: any) => {
          return category.value == this.$route.query.category;
        }).text;
        this.searchCategory = <string>this.$route.query.category;
      } else {
        this.category = "すべてのカテゴリー";
        this.searchCategory = "";
      }
      this.search();
    });
  }

  select(question: firebase.firestore.DocumentData) {
    this.$router.push(`/onayami/${question.id}`);
  }

  search(conditionChange: boolean = false) {
    this.isLoading = true;
    firebase
      .firestore()
      .collection("onayamis")
      .where("uid", "==", Common.getUserId())
      .orderBy("createTime", "desc")
      .get()
      .then((data: any) => {
        data.forEach((onayami: any) => {
          let result = onayami.data()!;
          let obj: any = {
            id: onayami.id,
            title: result.title,
            detail: result.detail,
            tags: result.tags,
            date: result.date,
            reward: result.reward,
            category: result.category,
            finish: result.finish,
            interrupt: result.interrupt,
            roomCount: result.roomCount ? result.roomCount : 0,
            rate: result.rate,
            rateNum: result.rateNum,
            userName: result.userName,
            restSlot: result.peopleLimit - result.inClass,
            createTime: new Date(result.createTime),
            uid: result.uid
          };
          this.questions.push(obj);
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  createFilterStr() {
    let filterStr = "";
    if (this.searchReward) {
      filterStr += `reward <= ${this.searchReward}`;
    }
    if (this.searchCategory) {
      let category = this.categories.find((category: any) => {
        return category.value == this.searchCategory;
      }).text;
      filterStr += filterStr ? " AND " : "";
      filterStr += `category:"${category}"`;
    }
    if (this.searchUid) {
      filterStr += filterStr ? " AND " : "";
      filterStr += `uid:"${this.searchUid}"`;
    }
    if (this.searchRate) {
      filterStr += filterStr ? " AND " : "";
      filterStr += `rate >= ${this.searchRate}`;
    }
    filterStr += filterStr ? " AND " : "";
    filterStr += this.endDataCheck ? "finish=0" : "(finish=0 OR finish=1)";
    filterStr += ' AND type:"class"';
    return filterStr;
  }

  sortClick(type: string) {
    if (this.searchSort != type) {
      this.searchSort = type;
      this.search(true);
    }
  }
}
